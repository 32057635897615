.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/*primary btn*/
.btn_primary {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color:#dc5a4b;
  border: none;
  border-radius: 34px;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #fff;
  padding: 0px 25px 0px 25px;
  height: 42px;
  min-width: 125px;
  text-align: center;
  transition: 0.2s all ease;
  -webkit-appearance: none;
}
.btn_primary.w_icon .el-icon {
  display: inline-flex;
  vertical-align: middle;
  margin-left: 10px;
}
.btn_primary.w_icon .el-icon img {
  display: inline-block;
  width: 100%;
  max-width: 18px;
}
.btn_primary.w-icon .el-icon {
  display: inline-flex;
  vertical-align: middle;
  margin-right: 6px;
}
.btn_primary.w-icon .el-icon img {
  display: inline-block;
  width: 100%;
  max-width: 18px;
}
.btn_primary.w-icon .el-text {
  display: inline-block;
  width: 100%;
  font-weight: 400;
  font-size: 18px;
  color: #fff;
  vertical-align: middle;
}
.btn_primary:hover {
  background-color: #e37b6f;
  color: #fff;
}
.btn_primary:focus {
  background-color: #dc5a4b;
  border-color: transparent;
  outline: none;
  box-shadow: none;
  color: #fff;
}
.btn_primary:focus:active {
  border-color: transparent;
  outline: none;
  box-shadow: none;
  color: #fff;
}
.btn_primary:focus-visible {
  background-color: #c44c3e;
  border-color: transparent;
  outline: none;
  box-shadow: none;
  color: #fff;
}
.btn_primary:focus-within {
  background-color: #dc5a4b;
  border-color: transparent;
  outline: none;
  box-shadow: none;
  color: #fff;
}
.btn_primary:active {
  background-color: #c65144 !important;
  border-color: transparent;
  outline: none;
  box-shadow: none;
  color: #fff !important;
}
.btn_primary:disabled {
  background-color: #f8dedb;
  border-color: transparent;
  outline: none;
  box-shadow: none;
  color: #fff;
}
.btn_primary.loader {
  background: #c65144;
}
.btn_primary.loader:before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 28px;
  height: 28px;
  /*background: url("/assets/images/btn_loader.svg") no-repeat;*/
  animation: btn-loader 1.4s linear infinite;
}



.cstm_dropdown .dropdown-toggle{
  width:100%;
  text-align: left;
  border: 1px solid #E0E1E5;
  background:#fff;
  color:#444;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;

}
.cstm_dropdown .dropdown-toggle:hover,
.cstm_dropdown .dropdown-toggle:active,
.cstm_dropdown .dropdown-toggle.show{
  background: #fff !important;
  border-color:#444 !important;
  color:#444 !important;
}
.cstm_dropdown .dropdown-menu{
  width:100%;
  background: #FFFFFF;
  border: 1px solid #EAECF0;
  box-shadow: 0 12px 16px -4px #1018281f, 0 4px 6px -2px #10182812;
  border-radius: 4px;
  color: #444;
  font-weight: 400;
  font-size: 1rem;
}


.loader_wrapper {
  width: 100%;
  height: calc(100vh - 120px);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cstm_loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #006878;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 0.8s linear infinite; /* Safari */
  animation: spin 0.8s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.success_message {
  text-align: center;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  height: 80vh;
  align-items: center;
  justify-content: center;
}

.success_message  p{
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #444;
}











