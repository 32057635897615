

.custom-table-wrap {
  .ant-table-container{
    table {
      border: 0px;
      width:100%;
      margin-bottom: 20px;
      table-layout: fixed!important;
      border-collapse: collapse;
      thead{
      tr{
        th{
          font-size: 14px;
          line-height: 19px;
          font-style: normal;
          font-weight: 400;
          color: #202124;
          padding: 10px;
          background-color: #fff;

          &:before{
            display: none;
          }
          &:first-child{
            width: 25%;
            font-weight: 700;
          }
          &:nth-child(2) {
            width: 30%;
            font-weight: 700;
          }

        }
      }


      }
      tbody{
        tr{
          border-bottom: 1px solid #E0E1E5;
          border-top: 1px solid #E0E1E5;
          transition: .12s all ease;
          cursor: pointer;
          &:hover{
            background:#f9f9f9;
            box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);

          }

          td{
            font-size: 14px;
            line-height: 19px;
            font-style: normal;
            font-weight: 400;
            color: #202124;
            border-bottom: 0px solid #E0E1E5;
            border-top: 0px solid #E0E1E5;
            border-left: 0px;
            border-right:0px ;
            border-spacing: 0px;
            padding: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &:first-child{
              font-weight: 700;
            }
            &:nth-child(2) {
              font-weight: 700;
            }
            &:nth-child(3){
              color: #5f6368;
            }

            p{
              margin: 0px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

          }
        }

      }
    }
  }
}


.email_header{
  padding: 12px 0 11px;
  height: 60px;
  position: sticky;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 9;
  transition: .4s ease-out;
  &:after{
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #FFFFFF;
    box-shadow: 0 0 8px #0003;
    z-index: -1;
  }
}
.inner_header_wrap{
  display: flex;
  .logo-wrap{
    .logo-ui{
      display: inline-flex;
      height: 30px;
    }

  }
}

.table_outer_container{
  padding: 40px;
  .table_inner_container{

  }
}
.pagination_outer{
  .pagination_inner{
    display: flex;
    align-items: center;
    justify-content: center;
    .ant-pagination{
      .ant-pagination-item-active{
      font-weight: 600;
        background-color: #DC5A4B;
        border-color: #DC5A4B;
        color: #fff;

      }
      .ant-pagination-item:not(.ant-pagination-item-active):hover {
        border-color: #F5F5F5;
      }

    }
  }
}







//css for home page starts here

.height_100{
  height: 100%;
}
.loginpage_wrapper{
  width: 100%;
}
.container-fluid{
  padding: 0 15px;
}

.login_left_wrap{
  height:100vh;
  width:100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center ;
  position: sticky;
  top: 0;
  left: 0;

}

.login_form_wrap{
  padding: 50px;

  .header_wrap{
    text-align: center;
    margin-top: 129px;
    display: flex;
    justify-content: center;

    .logo_ui {
      text-align: center;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      img{
        width: 180px;


      }
    }
  }
  .body_content_wrap{
    padding-top: 108px;
    max-width: 372px;
    margin: auto;
    position: relative;
    .welcom_txt{
      font-style: normal;
      font-weight: 300;
      //@include remy(20);
      line-height: 24px;
      //color: $border-dark;
      margin-bottom: 11px;
    }

    h3{
      font-style: normal;
      font-weight: 300;
      //@include remy(32);
      line-height: 38px;
      //color: $fc-primary;
      //font-family: $font_Souvenir_light;
      margin-bottom: 22px;

    }
    .btn_primary{
      width: 100%;
      height: 44px;
    }
  }
}

//css for home page ends here
